import { GET_VIDEOS, UPDATE_VIDEO, DELETE_VIDEO, VIDEO_ERROR } from "./types";
import axios from "axios";

export const getVideos = () => async (dispatch) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/videos?timestamp=${new Date().getTime()}`,
    );

    const videos = data.data.videos
      ? data.data.videos
      : data.data.data.videos
        ? data.data.data.videos
        : [];

    return dispatch({
      type: GET_VIDEOS,
      payload: videos,
    });
  } catch (error) {
    return dispatch({
      type: VIDEO_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const getMuxPlaybackUrl = async (assetId) => {
  try {
    const data = await axios.get(
      `https://mux-api.leandro-dae.workers.dev?assetId=${assetId}`,
    );
    return data.data;
  } catch (error) {
    return error;
  }
};

export const getMuxAssetByUploadId = async (uploadId) => {
  try {
    const data = await axios.get(
      `https://mux-api.leandro-dae.workers.dev?uploadId=${uploadId}`,
    );
    return data.data;
  } catch (error) {
    return error;
  }
};

export const createMuxURLUpload = async () => {
  try {
    const data = await axios.get(`https://mux-api.leandro-dae.workers.dev/`);
    return data.data;
  } catch (error) {
    return error;
  }
};

export const createVideo = (payload) => async (dispatch) => {
  try {
    if (payload.name.length > 0 && payload.thumbnail.length > 0) {
      const data = await axios.post(
        `${process.env.REACT_APP_GOLANG_API}/api/v2/videos`,
        payload,
      );

      return dispatch({
        type: UPDATE_VIDEO,
        payload: data.data,
      });
    }
  } catch (error) {
    return dispatch({
      type: VIDEO_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const editVideo = (payload, videoId) => async (dispatch) => {
  try {
    const data = await axios.patch(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/videos/${videoId}`,
      payload,
    );

    return dispatch({
      type: UPDATE_VIDEO,
      payload: data.data,
    });
  } catch (error) {
    return dispatch({
      type: VIDEO_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const deleteVideo = (videoId) => async (dispatch) => {
  try {
    const data = await axios.delete(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/videos/${videoId}`,
    );
    return dispatch({
      type: DELETE_VIDEO,
      payload: data.data,
    });
  } catch (error) {
    return dispatch({
      type: VIDEO_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};
