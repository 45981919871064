import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Spin, Button, Modal, message } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  deleteMKTEmail,
  getMKTEmails,
} from "../../store/actions/MKTEmailActions";
const { confirm } = Modal;

const emailTemplate = `
  <!DOCTYPE html>
  <html lang="pt-br">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Bem-vindo</title>
  </head>
  <body>
    <div style="font-family: Arial, sans-serif; line-height: 1.6; padding: 20px; background-color: #f4f4f4;">
      <div style="max-width: 100vw; margin: 0 auto; background-color: white; padding: 20px; border-radius: 5px; box-shadow: 0 0 10px rgba(0,0,0,0.1);">
        <h1 style="color: #333;">Welcome to Our Newsletter</h1>
        <p style="color: #555;">We are excited to have you here! In this newsletter, you will find all the latest updates about our products and services. Stay tuned for amazing content coming your way!</p>

        <h2 style="color: #333;">Latest Updates</h2>
        <p style="color: #555;">Here are some of the latest updates from our team:</p>
        <ul style="color: #555;">
          <li><strong>Update 1:</strong> We have launched a new feature that will help you manage your tasks more efficiently.</li>
          <li><strong>Update 2:</strong> Our mobile app now supports offline mode, so you can work on your projects even without an internet connection.</li>
          <li><strong>Update 3:</strong> We've partnered with some great new companies to bring you exciting new integrations.</li>
        </ul>

        <h2 style="color: #333;">Upcoming Events</h2>
        <p style="color: #555;">We have several upcoming events that we think you'll love:</p>
        <ul style="color: #555;">
          <li><strong>Webinar:</strong> Join us on September 25th for a live webinar on how to optimize your workflows.</li>
          <li><strong>Conference:</strong> We will be attending the Tech World Conference in October. Be sure to stop by our booth!</li>
          <li><strong>Meetups:</strong> Our local meetups are a great way to connect with others in the community. Find a meetup near you!</li>
        </ul>

        <h2 style="color: #333;">Customer Success Stories</h2>
        <p style="color: #555;">We love hearing how our customers are using our products. Here are some success stories from the past month:</p>
        <p style="color: #555;"><strong>John Doe:</strong> "This app has completely changed the way I manage my business. The new features are incredible!"</p>
        <p style="color: #555;"><strong>Jane Smith:</strong> "Thanks to this platform, I have been able to save hours each week on administrative tasks."</p>

        <h2 style="color: #333;">More Updates</h2>
        <p style="color: #555;">As always, we are continuously working to improve your experience. Here are a few more updates:</p>
        <ul style="color: #555;">
          <li>New integrations with third-party tools</li>
          <li>Improved performance and speed</li>
          <li>Updated user interface for a more intuitive experience</li>
          <li>Bug fixes and security improvements</li>
        </ul>

        <h2 style="color: #333;">FAQ</h2>
        <p style="color: #555;">Have questions? Here are some frequently asked questions from our community:</p>
        <p style="color: #555;"><strong>Q:</strong> How do I reset my password?<br><strong>A:</strong> Go to the settings page and click "Reset Password".</p>
        <p style="color: #555;"><strong>Q:</strong> Can I integrate this app with other tools?<br><strong>A:</strong> Yes! We offer integrations with many popular tools.</p>
        <p style="color: #555;"><strong>Q:</strong> How do I contact customer support?<br><strong>A:</strong> You can reach our support team through the help center on our website.</p>

        <h2 style="color: #333;">Thank You!</h2>
        <p style="color: #555;">We appreciate your support and look forward to providing you with even more great content in the future.</p>
        <p style="color: #555;">Best regards,<br>The Team</p>

        <div style="background-color: #f4f4f4; padding: 20px; margin-top: 40px;">
          <h3 style="color: #333;">Follow Us</h3>
          <p style="color: #555;">Stay connected with us on social media:</p>
          <ul style="color: #555;">
            <li><strong>Facebook:</strong> facebook.com/ourcompany</li>
            <li><strong>Twitter:</strong> twitter.com/ourcompany</li>
            <li><strong>LinkedIn:</strong> linkedin.com/company/ourcompany</li>
          </ul>
        </div>
      </div>
    </div>
  </body>
  </html>
`;

const MKTEmailList = ({ user, getMKTEmails, deleteMKTEmail, MKTEmails }) => {
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedMKTEmail, setSelectedMKTEmail] = useState(null);
  const userLevels = ["admin", "specialist", "sales", "editor"];

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getMKTEmails();
      setLoading(false);
    };
    getData();
  }, [getMKTEmails]);

  const showModal = (data) => {
    setSelectedMKTEmail(data);
    setModalVisible(true);
  };

  const handleOk = async () => {
    axios
      .get(
        `${process.env.REACT_APP_GOLANG_API}/api/v2/mkt-email-templates/${selectedMKTEmail.id}/send`,
      )
      .then((response) => {
        console.log("Sucesso:", response);
        message.success("E-mail enviado com sucesso.");
      })
      .catch((error) => {
        console.error("Erro:", error);
        message.error("Erro ao enviar o e-mail.");
      });
    setSelectedMKTEmail(null);
    setModalVisible(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "PPlans",
      dataIndex: "pplans",
      key: "pplans",
      render: (pplans) => pplans.join(", "),
    },
    {
      title: "Action",
      key: "action",
      width: "200px",
      render: (_, record) => (
        <Button.Group>
          <Button type="info" onClick={() => showModal(record)}>
            <EyeOutlined />
          </Button>
          {userLevels.includes(user.clearanceLevel) ? (
            <Button type="info">
              <Link to={`/mkt-emails/${record.id}/edit`}>
                <EditOutlined />
              </Link>
            </Button>
          ) : null}
          {user.clearanceLevel === "admin" ? (
            <Button
              type="danger"
              onClick={() =>
                confirm({
                  title: "Do you Want to delete this MKTEmail?",
                  okText: "Yes",
                  okType: "danger",
                  cancelText: "No",
                  onOk() {
                    deleteMKTEmail(record.id);
                  },
                })
              }
            >
              <DeleteOutlined />
            </Button>
          ) : null}
        </Button.Group>
      ),
    },
  ];

  return (
    <div>
      {userLevels.includes(user.clearanceLevel) ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "1rem",
          }}
        >
          <Button type="primary">
            <Link to="/mkt-emails/new">Create MKT Email</Link>
          </Button>
        </div>
      ) : null}
      <Spin spinning={loading}>
        <Table columns={columns} dataSource={MKTEmails} />
      </Spin>

      <Modal
        title="Email Template"
        visible={modalVisible}
        onCancel={() => setModalVisible((c) => !c)}
        footer={[
          <Button key="back" onClick={() => setModalVisible((c) => !c)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Enviar email
          </Button>,
        ]}
        destroyOnClose
        bodyStyle={{ minHeight: "85vh" }}
        width={"1000vw"}
        centered
      >
        <div
          dangerouslySetInnerHTML={{
            __html: selectedMKTEmail?.body || emailTemplate,
          }}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  MKTEmails: state.MKTEmails.MKTEmails,
});

export default connect(mapStateToProps, { getMKTEmails, deleteMKTEmail })(
  MKTEmailList,
);
