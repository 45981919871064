import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import "react-quill/dist/quill.snow.css";
import { updatePost, getPost } from "../../store/actions/postsActions";
import { fetchPdfs } from "../../store/actions/pdfsActions";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Spin,
  Switch,
  Upload,
} from "antd";
import Editor from "./Editor";

import { PlusOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import {
  getBase64,
  uploadOtherFilesToCloudflare,
} from "../../utils/uploadFileToCloudflare";

// title summary author(s) Date Time Status Category Tags Acessos body
const { TextArea } = Input;
const { Option } = Select;

const categories = [
  { name: "Fundamentalista", value: "fundamentalista" },
  { name: "Relatórios", value: "relatorio" },
  { name: "Ferramentas", value: "ferramentas" },
  { name: "Tecnofilosofia", value: "tecnofilosofia" },
  { name: "Tutoriais", value: "tutoriais" },
  { name: "Técnico", value: "tecnico" },
  { name: "Tokens", value: "tokens" },
];

const UpdatePost = ({
  form,
  updatePost,
  getPost,
  match,
  post,
  fetchPdfs,
  pdfs,
}) => {
  const [loading, setLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState(false);
  const [thumbnailURL, setThumbnailURL] = useState(null);
  const [text, setText] = useState("");
  const [fields, setFields] = useState([
    {
      name: "title",
      value: "",
    },
    {
      name: "shortLink",
      value: "",
    },
    {
      name: "summary",
      value: "",
    },
    {
      name: "source",
      value: "",
    },
    {
      name: "isFree",
      value: false,
    },
    {
      name: "data",
      value: "",
    },
    {
      name: "status",
      value: "",
    },
    {
      name: "category",
      value: "",
    },
    {
      name: "tags",
      value: [],
    },
    {
      name: "pdfs",
      value: [],
    },
  ]);

  const { id } = match.params;

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getPost(id);
      await fetchPdfs();
      setLoading(false);
    };
    getData();
  }, [id, getPost, fetchPdfs]);

  useEffect(() => {
    if (post) {
      setText(post.content);

      // const newArray = [];
      const newArray = Object.entries(post).map((entry) => {
        return {
          name: entry[0],
          value: entry[1],
        };
      });

      setFields(newArray);

      const newThumbnail = post.thumbnail || false;
      setThumbnail(newThumbnail);
    }
  }, [post]);

  const handleSubmit = async (values) => {
    setLoading(true);
    let fileURL = "";
    if (thumbnail.file) {
      fileURL = await uploadOtherFilesToCloudflare(thumbnail.file);
    } else {
      fileURL = thumbnailURL;
    }
    await updatePost(id, { ...values, thumbnail: fileURL }, text);
    setLoading(false);
    window.location.reload();
  };

  const handleUploadImg = (upload) => {
    getBase64(upload.file.originFileObj, (imageUrl) => {
      setThumbnail(upload);
      setThumbnailURL(imageUrl);
    });
  };

  const validateFields = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
    setLoading(false);
  };

  const handleChange = (fields) => {
    setFields(fields);
  };

  if (!post) return null;

  return (
    <Form
      name="global_state"
      onFinish={handleSubmit}
      fields={fields}
      onFinishFailed={validateFields}
      onFieldsChange={(_, allFields) => {
        handleChange(allFields);
      }}
    >
      <Spin spinning={loading}>
        <Row gutter={24}>
          <Col span={10}>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Input placeholder="title" />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              name="summary"
              label="Summary"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <TextArea placeholder="summary" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="source" label="Fonte">
              <Input placeholder="fonte" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="isFree"
              label="Free Content"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="shortLink" label="Link dinâmico">
              <Input placeholder="Link dinâmico" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <label className="ant-form-item-label">Content :</label>
            <Editor
              value={text}
              onChange={(value) => {
                setText(value);
              }}
            />
          </Col>
          <Col span={12}></Col>
          <Col span={6}></Col>
          <Col span={12}>
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Select style={{ width: "100%" }} placeholder="Please select">
                <Option value="draft">Draft</Option>
                <Option value="published">Published</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="category"
              label="Category"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Select style={{ width: "100%" }} placeholder="Please select">
                {categories.map((cat) => (
                  <Option value={cat.value} key={cat.value}>
                    {cat.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="tags"
              label="Tags"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Tags Mode"
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="pdfs"
              label="Pdfs"
              rules={[{ required: false, message: "this field is required" }]}
            >
              <Select
                allowClear
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Please select Pdfs"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {pdfs && pdfs.length > 0
                  ? pdfs.map((pdf, idx) => {
                      return (
                        <Option value={pdf.id} key={pdf.id}>
                          {pdf.name || "No Pdfs"}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <label className="ant-form-item-label">Thumbnail :</label>
            <div className="thumbnail-container">
              <Upload
                onChange={(upload) => handleUploadImg(upload)}
                name="thumbnail"
                listType="picture-card"
                className="upload-list-inline"
                showUploadList={false}
                onRemove={(...a) => setThumbnail(false)}
                fileList={
                  thumbnailURL
                    ? [
                        {
                          uid: "-4",
                          name: "Thumbnail",
                          status: "done",
                          url: thumbnailURL,
                        },
                      ]
                    : []
                }
              >
                {thumbnail ? (
                  <img
                    src={thumbnail}
                    alt="avatar"
                    style={{ width: "100%", height: "200px" }}
                  />
                ) : (
                  <PlusOutlined />
                )}
              </Upload>
            </div>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{
                display: "block",
                marginLeft: "auto",
                width: "100px",
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Spin>
    </Form>
  );
};
const mapStateToProps = (state) => ({
  post: state.posts.currentPost,
  pdfs: state.pdfs.pdfs,
});
export default connect(mapStateToProps, {
  updatePost,
  getPost,
  fetchPdfs,
})(withRouter(UpdatePost));
