import React from "react";
import { Alert, Input, Upload } from "antd";
import { beforeThumbnailUpload } from "../../utils/uploadFileToCloudflare";
import { PlusOutlined } from "@ant-design/icons";

import ImgCrop from "antd-img-crop";

const CreateUpdateForm = ({
  badgeError,
  handleInputChange,
  name,
  thumbnailURL,
  handleUpload,
}) => {
  return (
    <div>
      {badgeError && typeof badgeError === "string" ? (
        <div>
          {badgeError && typeof badgeError === "string" ? (
            <div>
              <Alert type="error" message={badgeError} />
            </div>
          ) : null}

          <div>
            <label>Name</label>
            <Input
              value={name}
              defaultValue={name}
              onChange={(e) => handleInputChange(e, "name")}
            />
          </div>

          <div style={{ marginTop: 15 }}>
            <label>Icon</label>
            <div className="thumbnail-container">
              <ImgCrop fillColor="#fff0">
                <Upload
                  name="thumbnail"
                  listType="picture-card"
                  accept="image/png, image/jpg, image/jpeg"
                  showUploadList={false}
                  beforeUpload={beforeThumbnailUpload}
                  onChange={(upload) => handleUpload(upload)}
                >
                  {thumbnailURL ? (
                    <img
                      src={thumbnailURL}
                      alt="avatar"
                      style={{ width: "100%", height: "200px" }}
                    />
                  ) : (
                    <PlusOutlined />
                  )}
                </Upload>
              </ImgCrop>
            </div>
          </div>
        </div>
      ) : null}

      <div>
        <label>Name</label>
        <Input
          value={name}
          defaultValue={name}
          onChange={(e) => handleInputChange(e, "name")}
        />
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Icon</label>
        <div className="thumbnail-container">
          <ImgCrop fillColor="#fff0">
            <Upload
              name="thumbnail"
              listType="picture-card"
              accept="image/png, image/jpg, image/jpeg"
              showUploadList={false}
              beforeUpload={beforeThumbnailUpload}
              onChange={(upload) => handleUpload(upload)}
            >
              {thumbnailURL ? (
                <img
                  src={thumbnailURL}
                  alt="avatar"
                  style={{ width: "100%", height: "200px" }}
                />
              ) : (
                <PlusOutlined />
              )}
            </Upload>
          </ImgCrop>
        </div>
      </div>
    </div>
  );
};

export default CreateUpdateForm;
