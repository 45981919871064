import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getPosts, deletePost } from "../../store/actions/postsActions";
import { Link } from "react-router-dom";
import { Table, Spin, Button, Tag, Modal, Avatar } from "antd";
import LinkModal from "./LinkModal";
import { DeleteOutlined, EditOutlined, LinkOutlined } from "@ant-design/icons";
const { confirm } = Modal;

function PostsList({ posts, getPosts, deletePost, user }) {
  const [loading, setLoading] = useState(true);
  const [selectedPostID, setSelectedPostID] = useState(null);
  const [postLinkModalVisible, setPostLinkModalVisible] = useState(false);
  const userLevels = ["admin", "specialist", "sales", "editor"];

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getPosts();
      setLoading(false);
    };
    getData();
  }, [getPosts]);

  const filteredPosts = (posts) => {
    if (userLevels.includes(user.clearanceLevel)) return posts;
    return posts.filter((post) => {
      return post.author === user.displayName || post.author === user.name;
    });
  };

  const showPostLinkModal = (id) => {
    setSelectedPostID(id);
    setPostLinkModalVisible(true);
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Summary",
      dataIndex: "summary",
      key: "summary",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => new Date(date * 1000).toISOString().slice(0, 10),
    },
    {
      title: "Free Content",
      dataIndex: "isFree",
      key: "isFree",
      render: (isFree) => (isFree ? "Yes" : "No"),
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
      render: (author) => <Tag>{author}</Tag>,
    },
    {
      title: "Author Photo",
      dataIndex: "authorPhotoURL",
      key: "authorPhotoURL",
      render: (_, record) => (
        <div>
          <Avatar
            shape="square"
            src={record?.authorPhotoURL !== null ? record?.authorPhotoURL : ""}
          />
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "200px",
      render: (_, record) => (
        <Button.Group>
          <Button type="info" onClick={() => showPostLinkModal(record.id)}>
            <LinkOutlined />
          </Button>
          {userLevels.includes(user.clearanceLevel) ? (
            <Button type="info">
              <Link to={`/posts/${record.id}/edit`}>
                <EditOutlined />
              </Link>
            </Button>
          ) : null}
          {user.clearanceLevel === "admin" ? (
            <Button
              type="danger"
              onClick={() =>
                confirm({
                  title: "Do you Want to delete this Post?",
                  okText: "Yes",
                  okType: "danger",
                  cancelText: "No",
                  onOk() {
                    deletePost(record.id);
                  },
                })
              }
            >
              <DeleteOutlined />
            </Button>
          ) : null}
        </Button.Group>
      ),
    },
  ];

  return (
    <div>
      {userLevels.includes(user.clearanceLevel) ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "1rem",
          }}
        >
          <Button type="primary">
            <Link to="/posts/new">Create Post</Link>
          </Button>
        </div>
      ) : null}
      <Spin spinning={loading}>
        <Table columns={columns} dataSource={filteredPosts(posts)} />
      </Spin>

      <LinkModal
        visible={postLinkModalVisible}
        toggle={() => setPostLinkModalVisible((c) => !c)}
        selectedPostID={selectedPostID}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  posts: state.posts.posts,
});

export default connect(mapStateToProps, { getPosts, deletePost })(PostsList);
