import React from "react";
import { Alert, Button, Col, Input, Progress, Row, Upload, Select } from "antd";
import { beforeThumbnailUpload } from "../../utils/uploadFileToCloudflare";
import { FileOutlined } from "@ant-design/icons";

import ImgCrop from "antd-img-crop";
const Option = Select.Option;
const CreateUpdateForm = ({
  pdfError,
  handleInputChange,
  handleSelectChange,
  kind,
  name,
  url,
  handleUpload,
  pdfUrl,
  beforeUpload,
  pdfUpload,
  pdfUploadProgress,
  thumbnailURL,
  handleUploadImg,
}) => {
  return (
    <div>
      {pdfError && typeof pdfError === "string" ? (
        <div style={{ marginBottom: 25 }}>
          <Alert type="error" message={pdfError} />
        </div>
      ) : null}

      <div style={{ marginTop: 25 }}>
        <label>Kind</label>
        <Select
          value={kind}
          defaultValue={kind}
          style={{ width: "100%" }}
          placeholder="website"
          onChange={(val) => handleSelectChange(val)}
        >
          <Option value="website">Website</Option>
          <Option value="video">Video</Option>
          <Option value="post">Post</Option>
        </Select>
      </div>

      <div>
        <label>Name</label>
        <Input
          value={name}
          defaultValue={name}
          onChange={(e) => handleInputChange(e, "name")}
        />
      </div>

      <div>
        <label>URL</label>
        <Input
          value={url}
          defaultValue={url}
          onChange={(e) => handleInputChange(e, "url")}
        />
      </div>

      <div style={{ marginTop: 25 }}>
        <Row gutter={4}>
          {pdfUrl ? (
            <Col>
              <Button href={pdfUrl} target="_blank">
                View current PDF
              </Button>
            </Col>
          ) : null}

          <Col>
            <Upload
              listType="picture"
              showUploadList={false}
              beforeUpload={(file) => beforeUpload(file)}
              accept="application/pdf"
              onChange={(upload) => handleUpload(upload)}
            >
              {pdfUpload ? (
                <div style={{ cursor: "pointer" }}>
                  <Progress
                    width={50}
                    type="circle"
                    percent={pdfUploadProgress}
                  />
                  <p style={{ color: "blue" }}>{pdfUpload.file.name}</p>
                </div>
              ) : (
                <Button icon={<FileOutlined />} size="middle">
                  Upload New PDF
                </Button>
              )}
            </Upload>
          </Col>
        </Row>

        <div style={{ marginTop: 15 }}>
          <label>Thumbnail</label>
          <div className="thumbnail-container">
            <ImgCrop
              aspect={kind === "website" ? 0.69 : 1}
              grid
              rotate
              quality={0.1}
              crop={{ x: 0, y: 0 }}
            >
              <Upload
                name="thumbnail"
                listType="picture-card"
                accept="image/png, image/jpg, image/jpeg"
                showUploadList={false}
                beforeUpload={beforeThumbnailUpload}
                onChange={(upload) => handleUploadImg(upload)}
              >
                {thumbnailURL ? (
                  <img
                    src={thumbnailURL}
                    alt="avatar"
                    style={{ width: "100%", height: "200px" }}
                  />
                ) : (
                  <FileOutlined />
                )}
              </Upload>
            </ImgCrop>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUpdateForm;
