import {
  GET_POSTS,
  GET_POST,
  ADD_POST,
  DELETE_POST,
  UPDATE_POST,
} from "./types";
import history from "../../history";
import firebase from "../../config/firebase";
import placeholder from "../../assets/image-placeholder.svg";
import axios from "axios";
import { getCookie, getUserDetailsFromCookie } from "../../utils/cookies";

export const getPosts =
  (category) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const data = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/posts?source=cms&timestap=${new Date().getTime()}`,
    );
    dispatch({
      type: GET_POSTS,
      payload: data.data.posts,
    });
  };

export const getPost = (id) => async (dispatch) => {
  try {
    const token = getCookie("_session");
    try {
      axios.defaults.headers.common["Authorization"] =
        `Bearer ${JSON.parse(token)}`;
    } catch (error) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    const doc = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/post/${id}`,
    );
    return dispatch({
      type: GET_POST,
      payload: doc.data.post,
    });
  } catch (error) {
    return error;
  }
};

export const createPost =
  ({
    title,
    shortLink,
    summary,
    source = "",
    status,
    category,
    tags,
    thumbnail,
    body,
    isFree,
    authorPhotoURL,
  }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const token = getCookie("_session");
      try {
        axios.defaults.headers.common["Authorization"] =
          `Bearer ${JSON.parse(token)}`;
      } catch (error) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }
      const user = getUserDetailsFromCookie();
      const newPost = {
        title,
        shortLink,
        summary,
        status,
        category,
        tags,
        source,
        isFree: isFree || false,
        thumbnail: thumbnail || false,
        author: user.displayName || user.name,
        authorPhotoURL: authorPhotoURL || user.authorPhotoURL,
      };

      const data = await axios.post(
        `${process.env.REACT_APP_GOLANG_API}/api/v2/posts`,
        { ...newPost, content: body },
      );

      dispatch({
        type: ADD_POST,
        payload: data.post,
      });

      // axios.put(
      //   `${process.env.REACT_APP_BACKEND_UPDATING_URL}/update-coins-with-posts`
      // );

      // axios.post(`${process.env.REACT_APP_GOLANG_API}/api/v2/short-links`, {
      //   name: doc.title,
      //   url: `https://post.paradigma.education/${shortLink}`,
      //   destination: `https://app.paradigma.education/feed/post/view?id=${doc.id}`,
      // });

      history.push("/posts");
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  };

export const updatePost =
  (id, updPost, body) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const post = { id, ...updPost, content: body };
      const token = getCookie("_session");
      try {
        axios.defaults.headers.common["Authorization"] =
          `Bearer ${JSON.parse(token)}`;
      } catch (error) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }
      await axios.patch(
        `${process.env.REACT_APP_GOLANG_API}/api/v2/posts/${id}`,
        post,
      );
      dispatch({
        type: UPDATE_POST,
        payload: post,
      });

      axios.put(
        `${process.env.REACT_APP_BACKEND_UPDATING_URL}/update-coins-with-posts`,
      );

      axios.post(`${process.env.REACT_APP_GOLANG_API}/api/v2/short-links`, {
        name: post.title,
        url: `https://post.paradigma.education/${post.shortLink}`,
        destination: `https://app.paradigma.education/feed/post/view?id=${id}`,
      });

      history.push("/posts");
    } catch (error) {
      return error;
    }
  };

export const deletePost =
  (id) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const token = getCookie("_session");
      try {
        axios.defaults.headers.common["Authorization"] =
          `Bearer ${JSON.parse(token)}`;
      } catch (error) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }
      await axios.delete(
        `${process.env.REACT_APP_GOLANG_API}/api/v2/posts/${id}`,
      );

      dispatch({
        type: DELETE_POST,
        payload: id,
      });
    } catch (error) {
      return error;
    }
  };

export function imageHandler() {
  const input = document.createElement("input");

  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click();

  input.onchange = async () => {
    const file = input.files[0];

    const range = this.quill.getSelection(true);

    this.quill.insertEmbed(range.index, "image", placeholder);

    this.quill.setSelection(range.index + 1);

    const url = await uploadToFirebase(file);

    this.quill.deleteText(range.index, 1);

    this.quill.insertEmbed(range.index, "image", url);
  };
}
export const uploadToFirebase = async (data) => {
  const metadata = {
    cacheControl: "public,max-age=36000",
  };
  const storageRef = firebase.storage().ref();
  const filename = Date.now() + "-" + data.name;
  const newImage = storageRef.child("/flamelink/media/" + filename);

  await newImage.put(data, metadata);
  return await newImage.getDownloadURL();
};
