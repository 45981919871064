import React, { useEffect, useState } from "react";
import { Table, Avatar, Row, Col, Spin } from "antd";
import { connect } from "react-redux";
import {
  fetchCoinsList,
  createStar,
} from "../../store/actions/coinsListActions";

import { Rating } from "react-simple-star-rating";

const CoinsList = ({
  coinsList,
  fetchCoinsList,
  createStar,
  user,
  // coinsListError
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await fetchCoinsList();
      setLoading(false);
    };
    getData();
  }, [fetchCoinsList]);

  const handleRating = async (id, rate) => {
    const payload = {
      star: rate,
      symbol: id,
    };
    await createStar(payload);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "market_cap_rank",
      key: "market_cap_rank",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (_, record) => (
        <div>
          <Avatar size="small" shape="square" src={record.image} />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Stars",
      dataIndex: "star",
      key: "x",
      render: (_, record) => (
        <div>
          <Row gutter={4}>
            <Col>
              {user.clearanceLevel === "admin" || user.clearanceLevel === "specialist" ? (
                <Rating
                  onClick={(rate) => handleRating(record.id, rate)}
                  transition
                  size={25}
                  initialValue={record.star}
                  allowFraction
                />
              ) : null}
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <div style={{ marginTop: 20 }}>
          <Spin spinning={loading}>
            <Table
              columns={columns}
              dataSource={Array.isArray(coinsList) ? coinsList : []}
              pagination={false}
            />
          </Spin>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  coinsList: state.coinsList.coinsList,
  coinsListError: state.coinsList.coinsListError,
});

export default connect(mapStateToProps, {
  fetchCoinsList,
  createStar,
})(CoinsList);
