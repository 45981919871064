import axios from "axios";
import { message } from "antd";

const url = "https://api.paradigma.education/api/v2/r2/signed-url";

const randomString = (length, chars, result = "") => {
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const getPresignedUrl = async (fileName, fileType) => {
  try {
    const response = await axios.get(`${url}?key=${fileType}/${fileName}`);
    return response.data.url;
  } catch (error) {
    console.error("Erro ao obter URL pré-assinada:", error);
    message.error("Falha ao obter URL de upload.");
    throw error; // Re-lança o erro para ser capturado na função de upload
  }
};

const uploadOtherFilesToCloudflare = async (file, fileType) => {
  try {
    const fileName = `${randomString(
      16,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
    )}.${file.name.split(".").pop()}`;

    const presignedUrl = await getPresignedUrl(fileName, fileType);

    // Enviar o arquivo diretamente no corpo da requisição PUT
    await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    // Retornar a URL acessível do arquivo
    return `https://assets.paradigma.education/${fileType}/${fileName}`;
  } catch (error) {
    console.error("Erro no upload:", error);
    message.error("Falha no upload do arquivo.");
    throw error; // Re-lança o erro para ser tratado onde for necessário
  }
};

const beforeThumbnailUpload = (file) => {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg";
  if (!isJpgOrPng) {
    message.error("Você pode fazer upload apenas de arquivos JPG/PNG!");
    return;
  }
  const isLt3M = file.size <= 3000000; // 3MB
  if (!isLt3M) {
    message.error("Imagem deve ser menor que 3MB!");
    return;
  }

  return isJpgOrPng && isLt3M;
};

export { uploadOtherFilesToCloudflare, beforeThumbnailUpload };
