import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Col,
  Input,
  Row,
  Table,
  Pagination,
  Modal,
  Menu,
  message,
} from "antd";
import { connect } from "react-redux";
import {
  fetchAllOrders,
  fetchAllLeads,
  fetchSalesReport,
  fetchAllUsers,
  getUserByEmail,
  updateUserPermission,
  updateUserPPlan,
  updateUserDiscordServer,
} from "../../store/actions/permissions";

import DiscordServerForm from "./DiscordServerForm";
import DetailsForm from "./DetailsForm";
import RolesForm from "./RolesForm";
import SubscriptionForm from "./SubscriptionForm";

const { Search } = Input;

const UsersAndPermissions = ({
  users,
  user,
  fetchAllUsers,
  getUserByEmail,
  updateUserPPlan,
}) => {
  const [searchedUsers, setSearchedUsers] = useState(null);
  const [total, setTotal] = useState(10);
  const [skip, setSkip] = useState(1);
  const [userData, setUserData] = useState([]);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [pplan, setPPlan] = useState("");
  const [isAuthor, setIsAuthor] = useState(false);
  const [isVideoCreator, setIsVideoCreator] = useState(false);
  const [id, setId] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeMenu, setActiveMenu] = useState("Details");
  const [clearanceLevel, setClearanceLevel] = useState(user.clearanceLevel);
  const [isDiscordServer, setIsDiscordServer] = useState(true);
  const [subscription, setSubscription] = useState({});
  const [feedback, setFeedback] = useState({});
  const [modalInfo, setModalInfo] = useState({
    name: "Join Discord",
    confirmBtnText: "Confirm",
    type: "joinDiscord",
  });

  // eslint-disable-next-line
  const emailRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const regexFinder = new RegExp(emailRegex);

  useEffect(() => {
    fetchAllUsers(skip);
  }, [fetchAllUsers, skip]);

  useEffect(() => {
    if (users.totalCount) {
      setTotal(users.totalCount[0].count);
    }
  }, [users]);

  const handleMenuClick = (e) => {
    setActiveMenu(e.key);
  };

  const getUserDetails = async (id) => {
    axios
      .get(`${process.env.REACT_APP_GOLANG_API}/api/v2/users/${id}/details`)
      .then((response) => {
        setSubscription(response.data.subscription);
        setFeedback(response.data.feedback);
      })
      .catch((error) => {
        console.error("Erro:", error);
      });
  };

  const openModal = async (modalMetadata, record) => {
    setModalInfo(modalMetadata);
    setIsModalVisible(true);
    if (record) {
      await getUserDetails(record.id);
      setUserData(record);
      setUsername(record ? record.username : "");
      setEmail(record ? record.email : "");
      setId(record ? record.id : "");
      setPPlan(record ? record.pplan : "");
      setIsAuthor(record.isAuthor ? record.isAuthor : false);
      setIsVideoCreator(record.isVideoCreator ? record.isVideoCreator : false);
      setClearanceLevel(
        record.clearanceLevel ? record.clearanceLevel : "Normal",
      );
    }
  };

  const handleInputChange = (e, type) => {
    if (type === "username") {
      setUsername(e.target.value);
    }

    if (type === "email") {
      setEmail(e.target.value);
    }

    if (type === "pplan") {
      setPPlan(e);
    }

    if (type === "isAuthor") {
      setIsAuthor(e);
    }

    if (type === "isVideoCreator") {
      setIsVideoCreator(e);
    }

    if (type === "clearanceLevel") {
      setClearanceLevel(e);
    }
  };

  const handleSalesExport = () => {
    axios
      .get(
        `${process.env.REACT_APP_GOLANG_API}/api/v2/orders/sales-report?email=${user.email}`,
      )
      .then((response) => {
        console.log("Sucesso:", response);
        message.success("E-mail enviado com sucesso.");
      })
      .catch((error) => {
        console.error("Erro:", error);
        message.error("Erro ao enviar o e-mail.");
      });
  };

  const handleCouponsPurchaseExport = () => {
    axios
      .get(
        `${process.env.REACT_APP_GOLANG_API}/api/v2/orders/coupons-purchase-report?email=${user.email}`,
      )
      .then((response) => {
        console.log("Sucesso:", response);
        message.success("E-mail enviado com sucesso.");
      })
      .catch((error) => {
        console.error("Erro:", error);
        message.error("Erro ao enviar o e-mail.");
      });
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "ClearanceLevel",
      dataIndex: "clearanceLevel",
      key: "clearanceLevel",
      render: (_, record) => (
        <div>
          <Button type="primary" danger={record.clearanceLevel === "admin"}>
            {record.clearanceLevel === "normal" ? "Normal" : record.clearanceLevel}
          </Button>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          <Row gutter={4}>
            <Col>
              <Button
                type="primary"
                onClick={() =>
                  openModal(
                    {
                      name: `Edit User`,
                      confirmBtnText: "Confirm",
                      type: "editUser",
                    },
                    record,
                  )
                }
              >
                Edit
              </Button>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  const clearState = () => {
    setUsername("");
    setActiveMenu("Details");
  };

  const closeModal = () => {
    setIsModalVisible(false);
    clearState();
  };

  const onSearch = async (value) => {
    if (value.trim() === "") {
      fetchAllUsers(skip);
    }

    if (regexFinder.test(value)) {
      await getUserByEmail(value);
    } else {
      setSearchedUsers(null);
      message.warning("Nenhum resultado encontrado.");
    }
  };

  return (
    <div>
      <div style={{ display: "flex", gap: 10 }}>
        <Row gutter={4}>
          <Col>
            <Button className="btn-dull-green" onClick={handleSalesExport}>
              Export Sales Report
            </Button>
          </Col>
        </Row>
        <Row gutter={4}>
          <Col>
            <Button
              className="btn-dull-green"
              onClick={handleCouponsPurchaseExport}
            >
              Export coupons purchase report
            </Button>
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: 20 }}>
        <Search
          placeholder="Search user by email"
          allowClear
          enterButton="Search"
          size="large"
          onSearch={onSearch}
        />
      </div>

      <div style={{ marginTop: 20 }}>
        <Table
          columns={columns}
          dataSource={searchedUsers ? searchedUsers : users.users}
        />
        <Pagination total={total} current={skip} onChange={setSkip} />
      </div>

      <Modal
        title={modalInfo.name}
        bodyStyle={{ minHeight: "80vh" }}
        width={1000}
        centered
        visible={isModalVisible}
        onCancel={closeModal}
        footer={null}
      >
        <Menu
          mode="horizontal"
          selectedKeys={[activeMenu]}
          onClick={handleMenuClick}
        >
          <Menu.Item key="Details">Details</Menu.Item>
          <Menu.Item key="Roles">Roles</Menu.Item>
          <Menu.Item key="Subscription">Subscription</Menu.Item>
          <Menu.Item key="DiscordServer">Discord Server</Menu.Item>
        </Menu>

        {modalInfo.type === "editUser" && activeMenu === "Details" ? (
          <DetailsForm userData={userData} />
        ) : null}

        {modalInfo.type === "editUser" && activeMenu === "Roles" ? (
          <RolesForm
            isAuthor={isAuthor}
            isVideoCreator={isVideoCreator}
            clearanceLevel={clearanceLevel}
            handleInputChange={handleInputChange}
            userId={id}
          />
        ) : null}

        {modalInfo.type === "editUser" && activeMenu === "Subscription" ? (
          <SubscriptionForm
            pplan={pplan}
            subscription={subscription}
            feedback={feedback}
            handleInputChange={handleInputChange}
            closeModal={closeModal}
            message={message}
            updateUserPPlan={updateUserPPlan}
            userId={id}
          />
        ) : null}

        {modalInfo.type === "editUser" && activeMenu === "DiscordServer" ? (
          <DiscordServerForm
            username={username}
            email={email}
            isDiscordServer={isDiscordServer}
            setIsDiscordServer={setIsDiscordServer}
            handleInputChange={handleInputChange}
          />
        ) : null}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: state.permissions.users,
  usersError: state.permissions.usersError,
  updatePermMessage: state.permissions.updatePermMessage,
  updatePermError: state.permissions.updatePermError,
  orders: state.permissions.orders,
  salesReport: state.permissions.salesReport,
  leads: state.permissions.leads,
});

export default connect(mapStateToProps, {
  fetchAllUsers,
  getUserByEmail,
  fetchAllOrders,
  fetchAllLeads,
  fetchSalesReport,
  updateUserPermission,
  updateUserPPlan,
  updateUserDiscordServer,
})(UsersAndPermissions);
