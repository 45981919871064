import React from "react"
import { useDispatch } from "react-redux";
import { updateUserClearanceLevel, } from "../../store/actions/permissions";
import { Alert, Select } from "antd";


const { Option } = Select;
const RolesForm = (props) => {
  const {
    handleInputChange,
    // isAuthor,
    // isVideoCreator,
    clearanceLevel,
    formError,
    userId,
  } = props;
  const dispatch = useDispatch();

  // const editUserPermission = (id, action) => {
  //   dispatch(
  //     updateUserPermission({
  //       id: id, 
  //       action: action
  //     }),
  //   );
  // };

  const editUserClearanceLevel = (id, clearanceLevel) => {
    dispatch(
      updateUserClearanceLevel({
        id: id, 
        clearanceLevel: clearanceLevel
      }),
    );
  };

  return (
    <div>
      {formError ? (
        <div style={{ marginBottom: 25 }}>
          <Alert type="error" message={formError} />
        </div>
      ) : null}

      {/* <div style={{ marginTop: 25 }}>
        <label>Author</label>
        <Select 
          style={{ width: '100%' }}
          defaultValue={isAuthor} 
          onChange={(e) => {
            handleInputChange(e, "isAuthor");
            editUserPermission(userId, "make-author");
          }}
        >
          <Option value={true}>True</Option>
          <Option value={false}>False</Option>
        </Select>
      </div>

      <div style={{ marginTop: 25 }}>
        <label>Video Creator</label>
        <Select 
          style={{ width: '100%' }}
          defaultValue={isVideoCreator} 
          onChange={(e) => {
            handleInputChange(e, "isVideoCreator")
            editUserPermission(userId, "make-video-creator");
          }}
        >
          <Option value={true}>True</Option>
          <Option value={false}>False</Option>
        </Select>
      </div> */}

      <div style={{ marginTop: 25 }}>
        <label>ClearanceLevel</label>
        <Select 
          style={{ width: '100%' }}
          defaultValue={clearanceLevel} 
          onChange={(e) => {
            handleInputChange(e, "clearanceLevel")
            editUserClearanceLevel(userId, e)
          }}
        >
          <Option value="admin">Admin</Option>
          <Option value="specialist">Specialist</Option>
          <Option value="sales">Sales</Option>
          <Option value="editor">Editor</Option>
        </Select>
      </div>
    </div>
  );
};

export default RolesForm;
