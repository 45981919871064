import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { createMKTEmail } from "../../store/actions/MKTEmailActions";
import { Form, Input, Button, Row, Col, Select, Spin, message } from "antd";
import parse from "html-react-parser";

const { Option } = Select;

const categories = [
  { name: "Vitalício", value: 6969 },
  { name: "Pro", value: 5 },
  { name: "Light", value: 3 },
  { name: "Team", value: 9393 },
];

const CreateMKTEmail = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [fields] = useState([
    { name: ["name"], value: "" },
    { name: ["subject"], value: "" },
    { name: ["body"], value: "" },
    { name: ["pplans"], value: [] },
  ]);

  useEffect(() => {
    // Fetch templates on component mount
    const fetchTemplates = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_GOLANG_API}/api/v2/sendgrid?timestamp=${Date.now()}`,
        );
        setTemplates(data);
      } catch (error) {
        message.error("Failed to fetch templates");
      }
    };

    fetchTemplates();
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await dispatch(
        createMKTEmail({
          ...values,
          body: text,
        }),
      );
      message.success("Email created successfully");
      history.push("/mkt-emails");
      window.location.reload();
    } catch (error) {
      message.error("Failed to create email");
      console.error("Error creating email:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetTemplate = async () => {
    if (!selectedTemplateId) {
      message.warning("Please select a template");
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_GOLANG_API}/api/v2/sendgrid/${selectedTemplateId}?timestamp=${Date.now()}`,
      );
      setText(data);
    } catch (error) {
      message.error("Failed to get template content");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      name="createEmailForm"
      onFinish={handleSubmit}
      fields={fields}
      layout="vertical"
    >
      <Spin spinning={loading}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="subject"
              label="Subject"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="Subject" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="templateId" label="Template ID">
              <Select
                placeholder="Select a template"
                onChange={setSelectedTemplateId}
                value={selectedTemplateId}
                allowClear
              >
                {templates.map((template) => (
                  <Option key={template.id} value={template.id}>
                    {template.name}
                  </Option>
                ))}
              </Select>
              <Button
                type="primary"
                onClick={handleGetTemplate}
                style={{ marginTop: 10 }}
              >
                Get Template
              </Button>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Content">
              <textarea
                rows={10}
                style={{
                  width: "100%",
                  border: "1px solid #d9d9d9",
                  outline: "none",
                }}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              {text && (
                <div style={{ marginTop: 16 }}>
                  <label className="ant-form-item-label">Preview:</label>
                  <div
                    style={{
                      border: "1px solid #ccc",
                      padding: 10,
                      minHeight: 150,
                      maxHeight: 500,
                      overflow: "auto",
                    }}
                  >
                    {parse(text)}
                  </div>
                </div>
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="pplans"
              label="Plans"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Please select"
                mode="multiple"
              >
                {categories.map((cat) => (
                  <Option value={cat.value} key={cat.value}>
                    {cat.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{
                display: "block",
                marginLeft: "auto",
                width: "100px",
              }}
            >
              Create
            </Button>
          </Col>
        </Row>
      </Spin>
    </Form>
  );
};

export default CreateMKTEmail;
