import {
  MODULE_ERROR,
  CREATE_MODULE,
  FETCH_MODULES,
  FETCH_MODULE,
  DELETE_MODULE,
  UPDATE_MODULE,
  FETCH_TRACKS,
  TRACK_ERROR,
  CREATE_TRACK,
  UPDATE_TRACK,
  DELETE_TRACK,
  SEARCH_SUCCESS,
  SEARCH_ERROR,
} from "./types";
import axios from "axios";

export const searchTrack = (searchText) => async (dispatch) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/search?q=${searchText}`,
    );
    return dispatch({
      type: SEARCH_SUCCESS,
      payload: data.data && data.data.data ? data.data.data : data.data,
    });
  } catch (error) {
    return dispatch({
      type: SEARCH_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const createTrack = (payload) => async (dispatch) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/video-tracks`,
      payload,
    );
    return dispatch({
      type: CREATE_TRACK,
      payload: data.data,
    });
  } catch (error) {
    return dispatch({
      type: TRACK_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const deleteTrack = (trackId) => async (dispatch) => {
  try {
    const data = await axios.delete(`
            ${process.env.REACT_APP_GOLANG_API}/api/v2/video-tracks/${trackId}`);
    return dispatch({
      type: DELETE_TRACK,
      payload: data.data,
    });
  } catch (error) {
    return dispatch({
      type: TRACK_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const updateTrack = (payload, trackId) => async (dispatch) => {
  try {
    const data = await axios.patch(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/video-tracks/${trackId}`,
      payload,
    );
    return dispatch({
      type: UPDATE_TRACK,
      payload: data.data,
    });
  } catch (error) {
    console.log("error", error);
    return dispatch({
      type: TRACK_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const fetchTracks = () => async (dispatch) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/video-tracks?includeDraft=true|false&timestamp=${new Date().getTime()}`,
    );

    if (data.data.data === null) data.data.data.concat([]);

    return dispatch({
      type: FETCH_TRACKS,
      payload: data.data.data,
    });
  } catch (error) {
    return dispatch({
      type: TRACK_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const setTracksError = (message) => (dispatch) => {
  return dispatch({
    type: TRACK_ERROR,
    payload: message,
  });
};

export const createModule = (payload) => async (dispatch) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/modules`,
      payload,
    );
    return dispatch({
      type: CREATE_MODULE,
      payload: data.data,
    });
  } catch (error) {
    return dispatch({
      type: MODULE_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const setModuleError = (message) => (dispatch) => {
  return dispatch({
    type: MODULE_ERROR,
    payload: message,
  });
};

export const updateModule = (payload, moduleId) => async (dispatch) => {
  try {
    const data = await axios.patch(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/modules/${moduleId}`,
      payload,
    );

    return dispatch({
      type: UPDATE_MODULE,
      payload: data.data,
    });
  } catch (error) {
    return dispatch({
      type: MODULE_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const fetchModule = (moduleId) => async (dispatch) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/modules/${moduleId}`,
    );
    return dispatch({
      type: FETCH_MODULE,
      payload:
        data.data && data.data.data
          ? data.data.data
          : data.data
            ? data.data
            : {
                name: "",
                description: "",
                videos: [],
              },
    });
  } catch (error) {
    return dispatch({
      type: MODULE_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const fetchModules = () => async (dispatch) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/modules?timestamp=${new Date().getTime()}`,
    );
    return dispatch({
      type: FETCH_MODULES,
      payload: data.data && data.data.data ? data.data.data : data.data,
    });
  } catch (error) {
    return dispatch({
      type: MODULE_ERROR,
      payload: error.response
        ? error.response.data.error
        : error.message
          ? error.message
          : error.error,
    });
  }
};

export const deleteModule = (moduleId) => async (dispatch) => {
  try {
    const data = await axios.delete(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/modules/${moduleId}`,
    );

    return dispatch({
      type: DELETE_MODULE,
      payload: data.data,
    });
  } catch (error) {
    return dispatch({
      type: MODULE_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};
