import {
  FETCH_BADGES,
  BADGE_ERROR,
  CREATE_BADGE,
  UPDATE_BADGE,
  DELETE_BADGE,
  FETCH_BADGE,
} from "./types";
import axios from "axios";

export const createBadge = (payload) => async (dispatch) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/badges`,
      payload,
    );
    dispatch({
      type: CREATE_BADGE,
      payload: data.data,
    });
    return setBadgeError("");
  } catch (error) {
    return dispatch({
      type: BADGE_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const setBadgeError = (message) => (dispatch) => {
  return dispatch({
    type: BADGE_ERROR,
    payload: message,
  });
};

export const updateBadge = (payload, badgeId) => async (dispatch) => {
  try {
    const data = await axios.patch(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/badges/${badgeId}`,
      payload,
    );
    return dispatch({
      type: UPDATE_BADGE,
      payload: data.data,
    });
  } catch (error) {
    return dispatch({
      type: BADGE_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const fetchBadge = (badgeId) => async (dispatch) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/badges/${badgeId}?timestamp=${new Date().getTime()}`,
    );
    return dispatch({
      type: FETCH_BADGE,
      payload:
        data.data && data.data.data
          ? data.data.data
          : data.data
            ? data.data
            : {
                name: "",
                description: "",
                videos: [],
              },
    });
  } catch (error) {
    return dispatch({
      type: BADGE_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const fetchBadges = () => async (dispatch) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/badges`,
    );
    return dispatch({
      type: FETCH_BADGES,
      payload: data.data && data.data.data ? data.data.data : data.data,
    });
  } catch (error) {
    return dispatch({
      type: BADGE_ERROR,
      payload: error.response
        ? error.response.data.error
        : error.message
          ? error.message
          : error.error,
    });
  }
};

export const deleteBadge = (badgeId) => async (dispatch) => {
  try {
    const data = await axios.delete(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/badges/${badgeId}`,
    );

    return dispatch({
      type: DELETE_BADGE,
      payload: data.data,
    });
  } catch (error) {
    return dispatch({
      type: BADGE_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};
